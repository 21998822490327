import { authConst } from 'src/redux/constants'

const initialState = {
  prefix: 'office',
  profile: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case authConst.SET_PROFILE:
      return { ...state, profile: action.payload }

    default:
      return state
  }
}
