export const IS_DEV = process.env.NODE_ENV !== 'production'

export const API_VERSION = 'v1'

export const baseUrl = IS_DEV
  ? 'https://api.kjmis.id'
  : // ? 'https://kjmis-dev-api.lenturin.com'
    'https://api.kjmis.id'

export * from './storage'
export * from './formatDate'
export * from './formatNumber'
