import { globalConst } from 'src/redux/constants'

const initialState = {
  author: 'awank <mas.awank100@gmail.com>',
  toastMessage: null,
  isLoading: false,
  loadingOverlay: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case globalConst.SET_TOAST_MESSAGE:
      return {
        ...state,
        toastMessage: action.payload
      }

    case globalConst.SET_LOADING_OVERLAY:
      return {
        ...state,
        loadingOverlay: action.payload
      }

    default:
      return state
  }
}
