import { combineReducers } from 'redux'
// Reducers
import globalReducer from './reducers/global'
import authReducer from './reducers/auth'

const reducer = combineReducers({
  Global: globalReducer,
  Auth: authReducer
})

export default reducer
