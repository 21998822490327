import CurrencyFormatter from 'currency-formatter'

export const formatPrice = (amount, precision = 0) => {
  const result = CurrencyFormatter.format(amount, {
    symbol: 'Rp',
    decimal: ',',
    thousand: '.',
    precision,
    format: '%s %v' // %s is the symbol and %v is the value
  })
  return result
}

export const formatNumber = (amount, precision = 0) => {
  const result = CurrencyFormatter.format(amount, {
    symbol: '',
    decimal: ',',
    thousand: '.',
    precision,
    format: '%v' // %s is the symbol and %v is the value
  })
  return result
}