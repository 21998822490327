import React from 'react'
import { Redirect, Router, Route, Switch } from 'react-router-dom'
import './App.scss'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'react-table-6/react-table.css'
import 'react-datepicker/dist/react-datepicker.css'
import history from './history'
import { useSelector } from 'react-redux'

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
)

// Layouts
const DefaultLayout = React.lazy(() => import('./layouts/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/Auth/Login'))

//kebijakan privasi
const PrivacyPolicy = React.lazy(() => import('./views/pages/privacy'))

const App = () => {
  const { profile } = useSelector((state) => state.Auth)
  
  return (
    <Router history={history}>
      <ToastContainer />
      <React.Suspense fallback={loading()}>
        <Switch>
          <Route
            exact
            path="/login"
            name="Login Page"
            render={(props) => <Login {...props} />}
          />
          <Route
            exact
            path="/privacy-policy"
            name="Privacy Policy"
            render={(props) => <PrivacyPolicy {...props} />}
          />
          <Route
            path="/"
            name="Home"
            render={(props) =>
              profile ? <DefaultLayout {...props} /> : <Redirect to="/login" />
            }
          />
        </Switch>
      </React.Suspense>
    </Router>
  )
}

export default App
